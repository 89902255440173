import { store } from '@/store';
import { getProjectFlagItems } from '@/cruds/fimalac.crud';
import { getFunctions } from '@/cruds/department.crud';

const state = () => ({
	usersNotShowing: [],
	flagFavorites: {
		isBookmarkFlags: [],
		isNotBookmarkFlags: [],
		all: []
	},
	flagsToStore: [],
	functionOrders: [],
	functionOrdersFiltered: []
});

const getters = {
	usersNotShowing: (state) => state.usersNotShowing,

	flagFavorites: (state) => state.flagFavorites.all,
	flagFavoritesBookmark: (state) => state.flagFavorites.isBookmarkFlags,
	flagFavoritesNotBookmark: (state) => state.flagFavorites.isNotBookmarkFlags,
	functionOrders: (state) => state.functionOrders,
	/**
	 *
	 * @param state
	 * @returns {[]|{departmentNumber: number, value: number}|*}
	 */
	functionOrdersFiltered: (state) => state.functionOrdersFiltered
};

const mutations = {
	SET_USERS_NOT_SHOWING(state, value) {
		state.usersNotShowing = value;
	},
	SET_FLAG_FAVORITES(state, value) {
		state.flagFavorites = value;
	},
	SET_FLAGS_TO_STORE(state, value) {
		state.flagsToStore = value;
	},
	SET_FUNCTION_ORDERS(state, values) {
		state.functionOrders = values;
	},
	SET_FUNCTION_ORDERS_FILTERED(state, values) {
		state.functionOrdersFiltered = values;
	}
};

const actions = {
	async addUsersNotShowing({ commit, state }, { userId, value }) {
		if (value === false) {
			state.usersNotShowing.push(+userId);
		} else if (value === true) {
			const findIndex = state.usersNotShowing.findIndex((option) => +option === +userId);
			state.usersNotShowing.splice(findIndex, 1);
		}

		commit('SET_USERS_NOT_SHOWING', state.usersNotShowing);
	},

	async getUsersNotShowing({ commit, state }) {
		if (window.localStorage.getItem(`fimalac_users_no_showing_${store.state.myProject.id}_${store.state.myProfile.id}`) !== null) {
			let usersNotShowingInStorage = window.localStorage.getItem(`fimalac_users_no_showing_${store.state.myProject.id}_${store.state.myProfile.id}`);
			usersNotShowingInStorage = JSON.parse(usersNotShowingInStorage);

			commit('SET_USERS_NOT_SHOWING', usersNotShowingInStorage);
		} else {
			commit('SET_USERS_NOT_SHOWING', []);
		}
	},

	async getFlagFavorites({ commit, state }, externalList = []) {
		let result = await getProjectFlagItems([4]);
		let isFavoriteFlags = [];
		let projectFlagItems = [];
		let flagItemsStored = [];
		if (result && result.projectFlagItems) {
			isFavoriteFlags = result.projectFlagItems.filter((isBook) => isBook.bookmark).map((item) => +item.id);
			projectFlagItems = result.projectFlagItems;
		}
		setTimeout(() => {
			let checkStorage = [];
			if (store && store.state && store.state.myProject && store.state.myProject.id) {
				checkStorage =
					JSON.parse(window.localStorage.getItem(`fimalac_flag_favorites_${store.state.myProject.id}_${store.state.myProfile.id}`)) === null
						? []
						: JSON.parse(window.localStorage.getItem(`fimalac_flag_favorites_${store.state.myProject.id}_${store.state.myProfile.id}`));
			}
			if (checkStorage.length !== 0) {
				flagItemsStored = window.localStorage.getItem(`fimalac_flag_favorites_${store.state.myProject.id}_${store.state.myProfile.id}`);
				flagItemsStored = JSON.parse(flagItemsStored);
			} else {
				if (isFavoriteFlags.length !== 0) {
					if (store && store.state && store.state.myProject && store.state.myProject.id !== undefined && store.state.myProfile.id !== undefined) {
						window.localStorage.setItem(
							`fimalac_flag_favorites_${store.state.myProject.id}_${store.state.myProfile.id}`,
							JSON.stringify(isFavoriteFlags)
						);
						flagItemsStored = window.localStorage.getItem(`fimalac_flag_favorites_${store.state.myProject.id}_${store.state.myProfile.id}`);
						flagItemsStored = JSON.parse(flagItemsStored);
					}
				}
			}
			let prepareResult = {
				isBookmarkFlags: [],
				isNotBookmarkFlags: [],
				all: []
			};
			projectFlagItems = projectFlagItems.map((option) => {
				if (flagItemsStored.length > 0) {
					if (flagItemsStored.includes(+option.id)) {
						const data = {
							...option,
							bookmark: true
						};
						prepareResult.isBookmarkFlags.push(data);
						prepareResult.all.push(data);
					} else {
						prepareResult.isNotBookmarkFlags.push({
							...option,
							bookmark: false
						});
						prepareResult.all.push({
							...option,
							bookmark: false
						});
					}
					return option;
				} else {
					if (option.bookmark) {
						prepareResult.isBookmarkFlags.push({
							...option
						});
					} else {
						prepareResult.isNotBookmarkFlags.push({
							...option,
							bookmark: false
						});
					}
				}
				prepareResult.all.push({
					...option
				});
				return option;
			});
			commit('SET_FLAG_FAVORITES', prepareResult);
			commit('SET_FLAGS_TO_STORE', flagItemsStored);
		}, 100);
	},

	async addFlagFavorites({ commit, state }, { flagItemId, value }) {
		if (value === true) {
			state.flagsToStore.push(+flagItemId);
		} else if (value === false) {
			const findIndex = state.flagsToStore.findIndex((option) => +option === +flagItemId);
			state.flagsToStore.splice(findIndex, 1);
		}

		commit('SET_FLAGS_TO_STORE', state.flagsToStore);
	},

	async saveUsersNotShowing({ commit, state }) {
		window.localStorage.setItem(`fimalac_users_no_showing_${store.state.myProject.id}_${store.state.myProfile.id}`, JSON.stringify(state.usersNotShowing));

		commit('SET_USERS_NOT_SHOWING', state.usersNotShowing);
	},

	async saveFlagFavorites({ commit, state, dispatch }) {
		window.localStorage.setItem(`fimalac_flag_favorites_${store.state.myProject.id}_${store.state.myProfile.id}`, JSON.stringify(state.flagsToStore));
		commit('SET_FLAGS_TO_STORE', state.flagsToStore);
		dispatch('getFlagFavorites');
	},

	async resetUsersNotShowing({ commit, state }) {
		const dataFromLocalStorage = window.localStorage.getItem(`fimalac_users_no_showing_${store.state.myProject.id}_${store.state.myProfile.id}`);
		if (dataFromLocalStorage !== null) {
			commit('SET_USERS_NOT_SHOWING', JSON.parse(dataFromLocalStorage));
		}

		commit('SET_USERS_NOT_SHOWING', []);
	},

	async resetFlagFavorites({ commit, state }) {
		const dataFromLocalStorage = window.localStorage.getItem(`fimalac_flag_favorites_${store.state.myProject.id}_${store.state.myProfile.id}`);
		if (dataFromLocalStorage !== null) {
			commit('SET_FLAGS_TO_STORE', JSON.parse(dataFromLocalStorage));
		}

		commit('SET_FLAGS_TO_STORE', []);
	},

	async getFunctionOrders({ commit }) {
		let functionOrdersStored = null;
		if (store && store.state && store.state.myProject && store.state.myProject.id) {
			functionOrdersStored = window.localStorage.getItem(`fimalac_function_orders_${store.state.myProject.id}_${store.state.myProfile.id}`);
		}
		let functionsBDD = await getFunctions();
		if (functionOrdersStored === null) {
			let result = [];
			if (functionsBDD) {
				functionsBDD.map((option) => {
					result.push({
						departmentNumber: option.departmentNumber,
						value: option.value
					});
				});
			}
			commit('SET_FUNCTION_ORDERS', functionsBDD);
			commit('SET_FUNCTION_ORDERS_FILTERED', result);
		} else {
			const functionOrdersStoredParsed = JSON.parse(functionOrdersStored);
			const resultPreparing = await prepareListFunctionOrders(functionsBDD, functionOrdersStoredParsed);
			commit('SET_FUNCTION_ORDERS', resultPreparing);
			commit('SET_FUNCTION_ORDERS_FILTERED', resultPreparing);
		}
	},

	async setFunctionOrders({ commit }, newFunctionOrders = []) {
		let result = [];
		newFunctionOrders.map((option) => {
			result.push({
				departmentNumber: option.departmentNumber,
				value: option.value
			});
		});
		window.localStorage.setItem(`fimalac_function_orders_${store.state.myProject.id}_${store.state.myProfile.id}`, JSON.stringify(result));

		commit('SET_FUNCTION_ORDERS', newFunctionOrders);
		commit('SET_FUNCTION_ORDERS_FILTERED', result);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};

async function prepareListFunctionOrders(listInBdd = [], listInLocalStorage = [], result = []) {
	if (listInBdd.length === 0) {
		return result;
	}

	if (listInLocalStorage.length > 0) {
		const functionStoredFindInBdd = listInBdd.findIndex(
			(option) => +option.departmentNumber === listInLocalStorage[0].departmentNumber && +option.value === listInLocalStorage[0].value
		);

		if (functionStoredFindInBdd > -1) {
			result.push({
				...listInBdd[functionStoredFindInBdd]
			});

			listInBdd.splice(functionStoredFindInBdd, 1);
			listInLocalStorage.splice(0, 1);
		} else {
			listInLocalStorage.splice(0, 1);
		}
	} else if (listInLocalStorage.length === 0) {
		listInBdd.map((option) => {
			result.push({
				...option
			});
		});

		listInBdd = [];
	}

	return prepareListFunctionOrders(listInBdd, listInLocalStorage, result);
}
