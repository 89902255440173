<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(47, 'Preferences')"
		header-class="header-class-modal-doc-package"
		size="xxl"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@cancel="handleCancel"
		@hidden="handleCancel"
		@ok.prevent="handleOk"
		modal-class="mui-animation"
		:fade="false"
	>
		<b-row class="mb-4">
			<b-col class="text-center">
				<b-button-group>
					<b-button :variant="`${!fromRubric ? '' : 'outline-'}primary`" @click="toggleFromRubric">
						{{ FormMSG(27, 'User list') }}
					</b-button>
					<b-button :variant="`${fromRubric ? '' : 'outline-'}primary`" @click="toggleFromRubric">
						{{ FormMSG(28, 'Favorite rubric') }}
					</b-button>
				</b-button-group>
			</b-col>
		</b-row>
		<div style="min-height: 150px">
			<b-collapse :visible="fromRubric === false">
				<div v-if="fromRubric === false">
					<b-row class="mb-2">
						<b-col>
							<b-input-group>
								<b-form-input type="text" v-model="filter" :placeholder="FormMSG(65, 'Type to search')" @keyup.enter="handleKeyUpEnter" />
								<b-input-group-append class="cursor-pointer">
									<b-input-group-text class="btn-search">
										<component :is="getLucideIcon('Search')" color="#FFFFFF" :size="16" :stroke-width="2.5" v-if="filter.length === 0" />
										<component :is="getLucideIcon('X')" color="#FFFFFF" :size="16" :stroke-width="2.5" @click="resetFilter" v-else />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col id="containerPreferencesTable" ref="containerPreferencesTable">
							<b-table
								v-if="$screen.width >= 992"
								selected-variant="primary"
								hover
								selectable
								select-mode="single"
								responsive="sm"
								ref="preferencesTable"
								id="preferencesTable"
								sticky-header="500px"
								:items="mapList"
								style="text-align: left"
								:fields="tableFields"
								bordered
								striped
								small
								head-variant="dark"
								:empty-text="FormMSG(250, 'No data found')"
								show-empty
								:busy="loadingSubmit"
							>
								<template #table-busy>
									<div><b-spinner small /> {{ FormMSG(815, 'Loading...') }}</div>
								</template>
								<template #cell(showing)="{ item, index }">
									<div class="checkbox-switch">
										<b-form-checkbox
											:id="`showing-${index}`"
											v-model="item.isUserVisible"
											:disabled="cannotBeDeleted(item)"
											switch
											size="lg"
											:value="true"
											:unchecked-value="false"
											@change="handleChangeShowing($event, index, 'isUserVisible')"
											:class="{ 'disabled-checkbox': cannotBeDeleted(item) }"
											v-b-tooltip.hover.top.html="isProduction ? handleHoverToolTip : ''"
										/>
									</div>
								</template>
								<template #cell(showSalary)="{ item, index }">
									<div class="checkbox-switch">
										<b-form-checkbox
											:id="`showSalary-${index}`"
											v-b-tooltip.hover.top.html="!isDisabledSalary && 'Show salary'"
											switch
											size="lg"
											:disabled="isDisabledSalary"
											v-model="item.showSalary"
											:value="true"
											:unchecked-value="false"
											@change="handleChangeShowing($event, index, 'showSalary')"
											:class="{ 'disabled-checkbox': isDisabledSalary }"
										/>
									</div>
								</template>
								<template #cell(ShowPrime)="{ item, index }">
									<div class="checkbox-switch">
										<b-form-checkbox
											:id="`showPrime-${index}`"
											v-b-tooltip.hover.top.html="!isDisabledSalary && 'Show prime'"
											switch
											size="lg"
											:disabled="isDisabledSalary"
											v-model="item.showPrime"
											:value="true"
											:unchecked-value="false"
											@change="handleChangeShowing($event, index, 'showPrime')"
											:class="{ 'disabled-checkbox': isDisabledSalary }"
										/>
									</div>
								</template>
								<template #cell(showDefrayal)="{ item, index }">
									<div class="checkbox-switch">
										<b-form-checkbox
											:id="`showDefrayal-${index}`"
											v-b-tooltip.hover.top.html="!isDisabledSalary && 'Show defrayal'"
											switch
											size="lg"
											:disabled="isDisabledSalary"
											v-model="item.showDefrayal"
											:value="true"
											:unchecked-value="false"
											@change="handleChangeShowing($event, index, 'showDefrayal')"
											:class="{ 'disabled-checkbox': isDisabledSalary }"
										/>
									</div>
								</template>
								<template #cell(delete)="{ item, index }">
									<div class="d-flex flex-row" style="gap: 4px"></div>
									<div>
										<b-button
											:disabled="cannotBeDeleted(item)"
											v-b-tooltip.hover.left.html="FormMSG(23, 'Remove user')"
											variant="primary"
											size="sm"
											@click="handleDeleteRow(item, index)"
											class="btn bg-transparent border-0"
										>
											<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
										</b-button>
									</div>
								</template>
							</b-table>
						</b-col>
					</b-row>
				</div>
			</b-collapse>

			<b-collapse :visible="fromRubric === true">
				<div v-if="fromRubric === true">
					<b-row class="mb-2">
						<b-col>
							<b-input-group>
								<b-form-input type="text" v-model="filterRubric" :placeholder="FormMSG(65, 'Type to search')" />
								<b-input-group-append class="cursor-pointer">
									<b-input-group-text class="btn-search">
										<component
											:is="getLucideIcon('Search')"
											color="#FFFFFF"
											:size="16"
											:stroke-width="2.5"
											v-if="filterRubric.length === 0"
										/>
										<component :is="getLucideIcon('X')" color="#FFFFFF" :size="16" :stroke-width="2.5" @click="filterRubric = ''" v-else />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col>
							<b-table
								v-if="$screen.width >= 992"
								selected-variant="primary"
								hover
								selectable
								select-mode="single"
								responsive="sm"
								ref="documentPackageTable"
								sticky-header="500px"
								:items="flagFavorites"
								style="text-align: left"
								:fields="tableFieldsRubric"
								bordered
								striped
								small
								head-variant="dark"
								:empty-text="FormMSG(250, 'No data found')"
								show-empty
								:filter="filterRubric"
							>
								<template #cell(bookmark)="{ item, index }">
									<b-button variant="custom-transparent" @click="handleClickFavorite(index)">
										<component
											:is="getLucideIcon('Star')"
											:size="20"
											color="#06263e"
											:fill="`${item.bookmark === true ? '#cf960b' : 'white'}`"
										/>
									</b-button>
								</template>
							</b-table>
						</b-col>
					</b-row>
				</div>
			</b-collapse>
		</div>

		<template #modal-footer="{ ok, cancel }">
			<div class="w-50 d-flex justify-content-end align-items-center">
				<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" :disabled="loadingSubmit" @click="cancel" block>
					{{ FormMSG(43, 'Cancel') }}
				</b-button>
				<b-button size="md" variant="primary" class="w-138-px" :disabled="loadingSubmit" @click="ok" block>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-if="loadingSubmit" small />
						<div :class="`${loadingSubmit ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(42, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getProjectFlagItems } from '@/cruds/fimalac.crud';
import { mapGetters, mapActions } from 'vuex';
import { getUsersForManageRole, changeUserAndSalaryVisibility, delUserFromProject } from '@/cruds/users.crud';
import { FIMALAC_PROJECT_FLAG, FIMALAC_PROJECT_LABEL_FLAG } from '@/shared/constants';

export default {
	name: 'Preferences',

	mixins: [languageMessages, globalMixin],

	props: {
		open: { type: Boolean, default: false, required: false },
		refreshModal: { type: Boolean, default: false, required: false },
		isProduction: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			loadingSubmit: false,
			filter: '',
			dataList: [],
			filterRubric: '',
			dataListRubric: [],
			fromRubric: false,

			recordLimit: 20,
			recordOffset: 0,
			alreadyLastData: false,

			usersShowingToStore: {
				toAdd: [],
				toRemove: []
			},

			favoritesToStore: {
				toAdd: [],
				toRemove: []
			},
			globalDataList: [],
			loadingTable: false
		};
	},

	watch: {
		filter: {
			handler(newVal) {
				if (newVal.length < 3) {
					this.resetFilter();
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		refreshModal: {
			async handler(newVal) {
				if (newVal === true) {
					this.recordOffset = 0;
					await this.getFlagFavorites();
					await this.getUsersForManageRole();
					this.$emit('preferences:refresh-end');
				}
			},
			deep: true
		},
		isOpen: {
			handler(newVal) {
				if (newVal) {
					this.getFlagFavorites();
					this.getUsersForManageRole();
					setTimeout(() => {
						let table = this.$refs['preferencesTable'];
						if (table !== undefined) {
							table.$el.addEventListener('scroll', this.handleScroll);
						}
					}, 200);
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		...mapGetters({
			usersNotShowing: 'fimalac/usersNotShowing',
			flagFavorites: 'fimalac/flagFavorites'
		}),
		isDisabledSalary() {
			return !this.isProduction;
		},
		mapList() {
			return this.generateList(this.dataList);
		},
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		tableFields() {
			return [
				{
					key: 'fullName',
					label: this.FormMSG(48, 'Name'),
					class: 'text-left w-1-30',
					sortable: true
				},
				{
					key: 'depFunction',
					label: this.FormMSG(94, 'Function'),
					class: 'text-left w-1-30',
					sortable: true
				},
				{
					key: 'showing',
					label: this.FormMSG(50, 'Showing'),
					class: 'text-center w-1-8',
					sortable: false
				},
				{
					key: 'showSalary',
					label: this.FormMSG(51, 'Show salary'),
					class: 'text-center w-1-8',
					sortable: true
				},
				{
					key: 'ShowPrime',
					label: this.FormMSG(52, 'Show prime'),
					class: 'text-center w-1-8',
					sortable: true
				},
				{
					key: 'showDefrayal',
					label: this.FormMSG(53, 'Show defrayal'),
					class: 'text-center w-1-8',
					sortable: true
				},
				{
					key: 'delete',
					label: this.FormMSG(130, 'Delete'),
					class: 'text-center w-1-8',
					sortable: false
				}
			];
		},
		tableFieldsRubric() {
			return [
				{
					key: 'rubric',
					label: this.FormMSG(125, 'Rubric'),
					class: 'text-left w-40',
					formatter: (value, key, item) => {
						return item.name;
					},
					sortable: true
				},
				{
					key: 'calendarOverview',
					label: this.FormMSG(127, 'Calendar overview'),
					class: 'text-center w-40',
					formatter: (value, key, item) => {
						return item.short;
					},
					sortable: true
				},
				{
					key: 'bookmark',
					label: this.FormMSG(129, 'Favorites'),
					class: 'text-center',
					sortable: false
				}
			];
		}
	},
	async created() {
		setTimeout(() => {
			let table = this.$refs['preferencesTable'];
			if (table !== undefined) {
				table.$el.addEventListener('scroll', this.handleScroll);
			}
		}, 1000);
	},

	methods: {
		...mapActions({
			addFlagFavorites: 'fimalac/addFlagFavorites',
			getFlagFavorites: 'fimalac/getFlagFavorites',
			removeFlagFavorites: 'fimalac/removeFlagFavorites',
			saveFlagFavorites: 'fimalac/saveFlagFavorites',
			resetFlagFavorites: 'fimalac/resetFlagFavorites'
		}),
		cannotBeDeleted(item) {
			if (item) {
				if (!this.isProduction) {
					// when user are the managers
					return true;
				}
				return item.cannotBeDeleted;
			}
			return true;
		},
		setFilterName(field, payload) {
			let obj = {};
			obj[field] = payload;
			return obj;
		},
		handleChangeShowing(payload, index, field) {
			this.dataList[index] = {
				...this.dataList[index],
				...this.setFilterName(field, payload)
			};
			const { id: newId } = this.dataList[index];
			this.globalDataList = this.globalDataList.map((item) => {
				const { id } = item;
				if (+id === +newId) {
					return this.dataList[index];
				}
				return item;
			});
		},
		generateList(dataList) {
			let defaultActionSalary = {
				showSalary: true,
				showPrime: true,
				showDefrayal: true
			};
			const data = dataList.map((item) => {
				const { SALARY, PRIME, DEFRAYAL } = FIMALAC_PROJECT_FLAG;
				const { managerSalaryAccess, id } = item;
				const toArray = Object.keys(item);
				const isShowSalary = toArray.includes('showSalary');
				const isShowPrime = toArray.includes('showPrime');
				const isShowDefrayal = toArray.includes('showDefrayal');
				const isPrecedentAction = {
					[SALARY]: { status: isShowSalary, value: isShowSalary ? item.showSalary : true },
					[PRIME]: { status: isShowPrime, value: isShowPrime ? item.showPrime : true },
					[DEFRAYAL]: { status: isShowDefrayal, value: isShowDefrayal ? item.showDefrayal : true }
				};
				if (managerSalaryAccess.length !== 0) {
					const TYPES = [SALARY, PRIME, DEFRAYAL];
					const { SALARY: labelSalary, PRIME: labelPrime, DEFRAYAL: labelDefrayal } = FIMALAC_PROJECT_LABEL_FLAG;
					const setUpperCase = (text) => {
						if (text) {
							return 'show' + text[0].toString().toUpperCase() + text.substring(1);
						}
						return '';
					};
					const defaultLabelSalary = {
						[SALARY]: labelSalary,
						[PRIME]: labelPrime,
						[DEFRAYAL]: labelDefrayal
					};
					let newDefaultActionSalary = {
						showSalary: true,
						showPrime: true,
						showDefrayal: true
					};
					TYPES.forEach((type) => {
						const access = managerSalaryAccess.find(({ managerID, salaryType }) => +managerID === +id && +salaryType === +type);
						if (access) {
							const { salaryType } = access;
							if (defaultLabelSalary[salaryType]) {
								newDefaultActionSalary = {
									...newDefaultActionSalary,
									[setUpperCase(defaultLabelSalary[salaryType])]: isPrecedentAction[salaryType]['status']
										? isPrecedentAction[salaryType]['value']
										: false
								};
							}
						} else {
							newDefaultActionSalary = {
								...newDefaultActionSalary,
								[setUpperCase(defaultLabelSalary[type])]: isPrecedentAction[type]['status'] ? isPrecedentAction[type]['value'] : true
							};
						}
					});
					return { ...item, ...newDefaultActionSalary };
				} else {
					const { showSalary: defaultShowSalary, showPrime: defaultShowPrime, showDefrayal: defaultShowDefrayal } = defaultActionSalary;
					return {
						...item,
						...defaultActionSalary,
						showSalary: isPrecedentAction[SALARY]['status'] ? isPrecedentAction[SALARY]['value'] : defaultShowSalary,
						showPrime: isPrecedentAction[PRIME]['status'] ? isPrecedentAction[PRIME]['value'] : defaultShowPrime,
						showDefrayal: isPrecedentAction[DEFRAYAL]['status'] ? isPrecedentAction[DEFRAYAL]['value'] : defaultShowDefrayal
					};
				}
			});
			return data;
		},
		async mapInitSalary(data, stateName, onSearch = false) {
			if (onSearch) {
				if (Array.isArray(data)) {
					this.$emit('on-show-is-loading', true);
					const _data = data.map((item) => {
						const { SALARY, PRIME, DEFRAYAL } = FIMALAC_PROJECT_FLAG;
						const TYPES = [SALARY, PRIME, DEFRAYAL];
						const { id, managerSalaryAccess } = item;
						let newDefaultActionSalary = {
							showSalary: true,
							showPrime: true,
							showDefrayal: true
						};
						const [showSalary, showPrime, showDefrayal] = Object.keys(newDefaultActionSalary);
						const flags = {
							[SALARY]: showSalary,
							[PRIME]: showPrime,
							[DEFRAYAL]: showDefrayal
						};
						const toArray = Object.keys(item);
						const isShowSalary = toArray.includes('showSalary');
						const isShowPrime = toArray.includes('showPrime');
						const isShowDefrayal = toArray.includes('showDefrayal');
						const isPrecedentAction = {
							[SALARY]: { status: isShowSalary, value: isShowSalary ? item.showSalary : true },
							[PRIME]: { status: isShowPrime, value: isShowPrime ? item.showPrime : true },
							[DEFRAYAL]: { status: isShowDefrayal, value: isShowDefrayal ? item.showDefrayal : true }
						};
						TYPES.forEach((type) => {
							const isManager = managerSalaryAccess.find(({ managerID, salaryType }) => +managerID === +id && +salaryType === +type);
							if (isManager) {
								const { salaryType } = isManager;
								if (flags[salaryType]) {
									newDefaultActionSalary = {
										...newDefaultActionSalary,
										[flags[salaryType]]: isPrecedentAction[salaryType]['status'] ? isPrecedentAction[salaryType]['value'] : false
									};
								}
							} else {
								newDefaultActionSalary = {
									...newDefaultActionSalary,
									[flags[type]]: isPrecedentAction[type]['status'] ? isPrecedentAction[type]['value'] : true
								};
							}
						});
						return {
							...item,
							id: +item.id,
							...newDefaultActionSalary
						};
					});
					this[`${stateName}`] = _data;
					const result = await new Promise((resolve, _) => {
						setTimeout(() => resolve(this[`${stateName}`]), 200);
					})
						.then((res) => {
							if (res) {
								this.$emit('on-show-is-loading', false);
								return res;
							}
						})
						.catch((error) => {
							this.$emit('on-show-is-loading', false);
							throw new error('Search failed: ' + error.message);
						});
					return result;
				}
				return this[`${stateName}`];
			} else {
				if (Array.isArray(data)) {
					this.$emit('on-show-is-loading', true);
					const result = data.map((item) => {
						const { SALARY, PRIME, DEFRAYAL } = FIMALAC_PROJECT_FLAG;
						const TYPES = [SALARY, PRIME, DEFRAYAL];
						const { id, managerSalaryAccess } = item;
						let newDefaultActionSalary = {
							showSalary: true,
							showPrime: true,
							showDefrayal: true
						};
						const [showSalary, showPrime, showDefrayal] = Object.keys(newDefaultActionSalary);
						const flags = {
							[SALARY]: showSalary,
							[PRIME]: showPrime,
							[DEFRAYAL]: showDefrayal
						};
						const toArray = Object.keys(item);
						const isShowSalary = toArray.includes('showSalary');
						const isShowPrime = toArray.includes('showPrime');
						const isShowDefrayal = toArray.includes('showDefrayal');
						const isPrecedentAction = {
							[SALARY]: { status: isShowSalary, value: isShowSalary ? item.showSalary : true },
							[PRIME]: { status: isShowPrime, value: isShowPrime ? item.showPrime : true },
							[DEFRAYAL]: { status: isShowDefrayal, value: isShowDefrayal ? item.showDefrayal : true }
						};
						TYPES.forEach((type) => {
							const isManager = managerSalaryAccess.find(({ managerID, salaryType }) => +managerID === +id && +salaryType === +type);
							if (isManager) {
								const { salaryType } = isManager;
								if (flags[salaryType]) {
									newDefaultActionSalary = {
										...newDefaultActionSalary,
										[flags[salaryType]]: isPrecedentAction[salaryType]['status'] ? isPrecedentAction[salaryType]['value'] : false
									};
								}
							} else {
								newDefaultActionSalary = {
									...newDefaultActionSalary,
									[flags[type]]: isPrecedentAction[type]['status'] ? isPrecedentAction[type]['value'] : true
								};
							}
						});
						if (this[`${stateName}`] !== undefined) {
							this[`${stateName}`].push({
								...item,
								id: +item.id,
								...newDefaultActionSalary
							});
						}
						return item;
					});
					this.$emit('on-show-is-loading', false);
					return result;
				}
				return this[`${stateName}`];
			}
		},
		handleHoverToolTip() {
			return `<div>${this.FormMSG(829, 'The user have timesheet(s)')}</div>`;
		},
		handleDeleteRow(item, index) {
			let _item = item;
			let userId = +item.id;
			let txt = this.FormMSG(131, 'Please confirm that you want delete this user');
			let result = this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.FormMSG(132, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: 'ok',
					cancelTitle: 'Cancel',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false,
					modalClass: 'mui-animation'
				})
				.then(async (v) => {
					if (!v) return;
					else {
						let response = await delUserFromProject(userId);
						if (response) {
							this.dataList = this.dataList.filter((item) => +item.id !== +_item.id);
							return this.$bvToast.toast(this.FormMSG(133, 'User delete with success'), {
								title: 'success',
								variant: 'success',
								solid: true,
								toaster: 'b-toaster-top-center',
								autoHideDelay: 3000
							});
						} else {
							return this.$bvToast.toast(this.FormMSG(134, 'Error to delete an user'), {
								title: 'Error',
								variant: 'error',
								solid: true,
								toaster: 'b-toaster-top-center',
								autoHideDelay: 3000
							});
						}
					}
				})
				.catch(() => {
					return false;
				});
			return result;
		},
		getTypeFlag(key) {
			const execute = (text) => {
				const { SALARY, PRIME, DEFRAYAL } = FIMALAC_PROJECT_LABEL_FLAG;
				switch (text) {
					case SALARY:
						return FIMALAC_PROJECT_FLAG.SALARY;
					case PRIME:
						return FIMALAC_PROJECT_FLAG.PRIME;
					case DEFRAYAL:
						return FIMALAC_PROJECT_FLAG.DEFRAYAL;
					default:
						return FIMALAC_PROJECT_FLAG.GROSS;
				}
			};
			return execute(key);
		},
		async onSubmitted() {
			const data = this.mapList;
			const formatData = data.map((item) => {
				let access = [];
				const { id, isUserVisible, showSalary, showPrime, showDefrayal } = item;
				const { SALARY, PRIME, DEFRAYAL } = FIMALAC_PROJECT_LABEL_FLAG;
				const types = [
					{ type: SALARY, status: showSalary },
					{ type: PRIME, status: showPrime },
					{ type: DEFRAYAL, status: showDefrayal }
				];
				types.forEach(({ status, type }, _) => {
					access.push({ managerID: +id, disable: !status, salaryType: +this.getTypeFlag(type) });
				});
				return { userVisibility: { userId: +id, isUserVisible }, managerSalaryAccess: access };
			});
			let response = await changeUserAndSalaryVisibility(formatData);
			return response;
		},
		async resetFilter() {
			this.$emit('on-show-is-loading', true);
			const position = this.recordOffset + 1;
			this.dataList = this.globalDataList.slice(0, this.recordLimit * position);
			this.$emit('on-show-is-loading', false);
		},

		async handleKeyUpEnter() {
			let limit = null;
			let offset = null;
			let keyword = this.filter;
			if (keyword.length >= 3) {
				const data = [...(await getUsersForManageRole(limit, offset, keyword, null, true))].map((item) => {
					const { id } = item;
					const isItem = this.globalDataList.find(({ id: precedId }) => precedId === id);
					if (isItem) {
						return isItem;
					}
					return { ...item, showing: item.isUserVisible, depFunction: `${item.departmentName} - ${item.functionName}` };
				});
				return this.mapInitSalary(data, 'dataList', true);
			}
		},

		async handleScroll(event) {
			const scrollTop = event.target.scrollTop;
			const scrollHeight = event.target.scrollHeight;
			const clientHeight = event.target.clientHeight;
			if (scrollTop + clientHeight + 10 >= scrollHeight) {
				this.recordOffset += 1;
				let limit = this.recordLimit;
				let offset = this.recordOffset;
				let keyword = null;
				let result = [...(await getUsersForManageRole(limit, offset, keyword, null, true))].map((item) => ({
					...item,
					showing: item.isUserVisible,
					depFunction: `${item.departmentName} - ${item.functionName}`
				}));
				const data = [...this.dataList, ...result];
				this.dataList = data;
			}
		},

		handleOk() {
			this.loadingSubmit = true;
			this.onSubmitted();
			this.saveFlagFavorites();
			this.emitEventClose();
			this.$emit('is-success-to-refresh');
			this.loadingSubmit = false;
		},
		async getProjectFlagItems() {
			const result = await getProjectFlagItems([4]);

			this.dataListRubric = result.projectFlagItems;
		},
		async getUsersForManageRole() {
			let limit = this.recordLimit;
			let offset = this.recordOffset;
			let keyword = null;
			let result = [...(await getUsersForManageRole(limit, offset, keyword, null, true))].map((item) => ({
				...item,
				showing: item.isUserVisible,
				depFunction: `${item.departmentName} - ${item.functionName}`
			}));
			this.dataList = result;
			this.globalDataList = [...(await getUsersForManageRole(null, null, null, null, true))].map((item) => ({
				...item,
				showing: item.isUserVisible,
				depFunction: `${item.departmentName} - ${item.functionName}`
			}));
			return this.dataList;
		},
		handleClickFavorite(index) {
			const indexFinded = this.flagFavorites[index];
			this.flagFavorites[index].bookmark = !indexFinded.bookmark;

			this.addFlagFavorites({ flagItemId: indexFinded.id, value: this.flagFavorites[index].bookmark });
		},
		toggleFromRubric() {
			this.fromRubric = !this.fromRubric;
		},
		emitEventClose() {
			this.recordLimit = 20;
			this.recordOffset = 0;
			this.keyword = null;
			this.$emit('preferences:close');
		},
		handleCancel() {
			clearTimeout();
			this.emitEventClose();
		}
	}
};
</script>
<style lang="scss">
.w-1-8 {
	width: 8%;
}
.w-1-30 {
	width: 30%;
}
.checkbox-switch {
	.disabled-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
		color: #fff !important;
		background: #c8e3e3 !important;
		cursor: pointer !important;
	}
}
</style>
