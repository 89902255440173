/**
 * @component   GlobalBaseMixin Plugin
 */

import Vue from 'vue';
import { isNil, makeID } from '@/shared/utils';
import { getFileNameFromURL } from '@/shared/helpers';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { store } from '@/store';
import axios from 'axios';
import _ from 'lodash';

if (!Vue.__my_mixin__) {
	Vue.__my_mixin__ = true;
	Vue.mixin({
		name: 'GlobalBaseMixin',
		computed: {
			currentLang() {
				let res = 'en';
				const langStore = store && store.state && store.state.myProfile && store.state.myProfile.appLanguage ? store.state.myProfile.appLanguage : 0;
				switch (langStore) {
					case 0:
						res = 'en';
						break;
					case 1:
						res = 'fr';
						break;
					case 2:
						res = 'de';
						break;
					case 3:
						res = 'it';
						break;
					case 4:
						res = 'nl';
						break;
					case 5:
						res = 'en';
						break;
					case 6:
						res = 'pt-br';
						break;
					case 7:
						res = 'sk';
						break;
				}
				return res;
			}
			/**
			 * Determine the mobile operating system.
			 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
			 *
			 * @returns {String}
			 */
		},
		methods: {
			/**
			 * Check if slot exist in component
			 *
			 * @param {String} name
			 * @return {Boolean}
			 */
			$hasSlot(name = 'default') {
				return !!this.$slots[name] || !!this.$scopedSlots[name];
			},
			/**
			 * @return {Boolean}
			 */
			$isPwa() {
				// const types = ['fullscreen', 'standalone', 'minimal-ui']
				// return types.some(dm => window.matchMedia(`(display-mode: ${dm})`).matches)
				const res = Capacitor.getPlatform() !== 'web' || Capacitor.isNativePlatform();
				// console.log({ res })
				return res;
			},
			/**
			 * check if the app is in "responsive" format
			 * @return {Boolean}
			 */
			$isResponsive() {
				return window.innerWidth <= 768;
			},
			/**
			 * @param {Array} images
			 * @param {Number|null} focusIndex
			 */
			$previewImages({ name = null, images, focusIndex = null, options = {} }, eventCallback) {
				this.$bus.$emit('open-showroom-images', {
					name: isNil(name) ? `default_previewer_name__${makeID(10)}` : name,
					images,
					focusIndex,
					options
				});
			},
			$previewerEventListener(name, callback) {
				return this.$bus.$on(`on_documents_previewer_event__${name}`, (bus) => {
					callback({ on_documents_previewer_event__: bus });
				});
			},
			/**
			 * @param {Object} el
			 * @param {Object} options
			 */
			$simpleScrollTo(el, options = {}) {
				if (isNil(el)) return;
				const _o = {
					behavior: 'smooth',
					offset: 0,
					...options
				};

				el.scrollIntoView(true);

				const scrolledY = window.scrollY;
				window.scrollTo({ top: scrolledY - _o.offset, ..._o });
			},
			/**
			 * @param {String} refName
			 */
			autoScrollToEnd(refName) {
				const el = this.$refs[refName];
				const _el = isNil(el.$el) ? el : el.$el;
				_el.scrollTop = _el.scrollHeight;
			},
			mobileOperatingSystem() {
				var userAgent = navigator.userAgent || navigator.vendor || window.opera;

				// Windows Phone must come first because its UA also contains "Android"
				if (/windows phone/i.test(userAgent)) {
					return 'Windows Phone';
				}

				if (/android/i.test(userAgent)) {
					return 'Android';
				}

				// iOS detection from: http://stackoverflow.com/a/9039885/177710
				if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
					return 'iOS';
				}

				return 'unknown';
			},
			/**
			 * @return {Boolean}
			 */
			$isIOSDevice() {
				return this.mobileOperatingSystem() === 'iOS';
			},
			/**
			 * @return {Boolean}
			 */
			$isAndroidDevice() {
				return this.mobileOperatingSystem() === 'Android';
			},
			$hasChild(id) {
				return document.getElementById(`${id}`) && document.getElementById(`${id}`).childNodes.length > 0;
			},
			async downloadUrl(url, fileName = null, headersOptions = {}) {
				try {
					const _fn = isNil(fileName) ? getFileNameFromURL(url) : fileName;

					if (this.$isPwa()) {
						await Browser.open({
							url,
							presentationStyle: 'popover',
							windowName: _fn
						});
					} else {
						const response = await fetch(url, {
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
								...headersOptions
							}
						});

						const blob = await response.blob();
						const objUrl = window.URL.createObjectURL(blob);
						const link = document.createElement('a');
						link.setAttribute('href', objUrl);
						link.setAttribute('target', '_blank');
						link.setAttribute('download', _fn);
						document.body.appendChild(link);
						link.click();
					}
				} catch (e) {
					console.error({ e });
				}
			},
			async downloadVcf(url, headers, isIos = false, fileName = null) {
				const response = await axios.get(url, {
					headers
				});

				// console.log({ response });
				if (!isIos) {
					const fileDownloadLink = process.env.VUE_APP_GQL + '/images/' + response.data;
					const fn = isNil(fileName) ? getFileNameFromURL(url) : fileName;
					// window.open(fileDownloadLink, '_self', '');
					let link = document.createElement('a');
					link.setAttribute('href', fileDownloadLink);
					link.setAttribute('target', '_blank');
					link.setAttribute('download', fn);
					document.body.appendChild(link);
					link.click();
				}

				return response;
			},
			forceDownload(xid, customFileName = null) {
				let fileName = !_.isNil(customFileName) && !_.isEmpty(customFileName) ? customFileName : xid;
				const url = process.env.VUE_APP_GQL + '/images/' + xid;
				axios.get(url, { responseType: 'blob' }).then((response) => {
					const link = window.URL.createObjectURL(new Blob([response.data]));
					const anchor = document.createElement('a');
					anchor.setAttribute('href', link);
					anchor.setAttribute('target', '_blank');
					anchor.setAttribute('download', fileName);
					document.body.appendChild(anchor);
					anchor.click();
					document.body.removeChild(anchor);
				});
			}
		}
	});
}
